import React from 'react'

import CineVista from '../../sections/cases/cinevista/CineVista'
import styled from 'styled-components'

const PageContainer = styled.div`
    
`

const CineVistaPage = () => {

    return (
        <>
            <PageContainer>
                <CineVista />
            </PageContainer>
            
        </>
    )
}

export default CineVistaPage
