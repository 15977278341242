import React from 'react'

import styled from 'styled-components'
import { Container, Box, Typography } from '@material-ui/core'
import Cover from './subsections/Cover'
import Overview from './subsections/Overview'
import caseTheme from './caseTheme'
import Research from './subsections/Research'


const Title = styled.h1`
    color: #FFFF;
`
const SubTitle = styled.h2``
const Paragraph = styled.p``


const TitleSection = styled.div`
    background-color: ${caseTheme.accentColor};
    height: 100vh;
    text-align: center;
    display: flex;
    align-items: center;
`
const ContentSection = styled.div`
    background-color: ${caseTheme.primaryColor};
    min-height: 100vh;
    padding-top: ${props => props.theme.space[4]}px;
    padding-bottom: ${props => props.theme.space[4]}px;
`

const CineVista = () => {
    return (
        <>
            
            <ContentSection>
                <Container>
                    <Cover/>
                </Container>
                <Container>
                    <Overview />
                </Container>
            </ContentSection>

            <TitleSection>
                <Container>
                    <Title>Hello world</Title>

                </Container>
            </TitleSection>
            <ContentSection>
                <Container>
                    <Research />
                </Container>
            </ContentSection>
        </>
    )
}

export default CineVista
