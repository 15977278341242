import React from 'react'
import { Title, Subtitle, Paragraph } from '../Typography'
import styled from 'styled-components'
import caseTheme from '../caseTheme'
import { AssessmentOutlined } from '@material-ui/icons'
import { Grid } from '@material-ui/core'
import ColorAvatar from '../components/ColorAvatar'

const summary1 = "I conducted interviews and created empathy maps to understand the users I’m designing for and their needs. A primary user group identified through research were community college students in the local town who frequent the theater after classes."
const summary2 = "Although this user group confirmed initial assumptions about Cine Vista customers, research also revealed that time was not the only factor limiting users from going to the theater. For instance, multiple users shared a desire for knowing additional information about a particular movie such as the runtime, rating, and review-aggregation score before reserving tickets."

const Card = styled.div`
    background-color: ${caseTheme.secondaryColor};
    border-radius: 32px;
    padding: ${props => props.theme.space[4]}px;
    margin-top: ${props => props.theme.space[4]}px;
    margin-bottom: ${props => props.theme.space[4]}px;
    text-align: center;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
`
const CardAvatar = styled(ColorAvatar)`
    margin-top: -50px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
`


const SummaryCard = ({children}) => {
    return (
        <Card>
            <CardAvatar>
                <AssessmentOutlined/>
            </CardAvatar>
            {children}
        </Card>
    )
}
const Painpoint = ({ number, title, text }) => {
    
    return (
        <Grid item sm={4}>
            <ColorAvatar>
                { number }
            </ColorAvatar>
            <Subtitle>{ title }</Subtitle>
            <Paragraph>{ text }</Paragraph>
        </Grid>
    )
}

const Research = () => {

    const text1="Users aren’t willing to spend more than five minutes to find a movie and book tickets."
    const text2="Users want to make informed decisions about the movies they want to watch instead of wasting time and money on bad movies."
    const text3="Users want to be aware of where they are during the reservation process instead of going through unnecessary steps."

    return (
        <div>
            <Title>UX Research</Title>
            <Subtitle>Summary</Subtitle>
            <SummaryCard>
                
                <Paragraph>{summary1}</Paragraph>
                <Paragraph>{summary2}</Paragraph>
            </SummaryCard>
            <Subtitle>Pain Points</Subtitle>
            <Grid container>
                <Painpoint number={1} title="Time" text={text1}/>
                <Painpoint number={2} title="Decision Making" text={text2}/>
                <Painpoint number={3} title="IA" text={text3}/>
            </Grid>
            <Subtitle>Personas</Subtitle>
            <Grid>
                
            </Grid>
        </div>
    )
}

export default Research
