import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import logo from '../../../../assets/casestudies/cinevistalogo.svg'
import image from '../../../../assets/casestudies/image1.png'
import { Title } from '../Typography'


const Logo = styled.img`
    width: 200px;
`
const Image = styled.img`
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${props => props.theme.space[2]}px;
    margin-top: ${props => props.theme.space[2]}px;
`

const Cover = () => {
    return (
        <>
            <Grid style={{height: '100vh'}} container alignItems={'center'}>
                <Grid item sm={6}>
                    <Logo src={logo}/>
                    <Title>Movie Theater Seat Reservation App</Title>
                </Grid>
                <Grid item container sm={6}>
                    <Image src={image} />
                </Grid>
            </Grid>
            
            
        </>
    )
}

export default Cover
