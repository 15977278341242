import React from 'react'
import styled from 'styled-components'



export const Title = styled.h1`
    color: white;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
`
export const Subtitle = styled.h2`
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    color: #FDA4AF;
`
export const Paragraph = styled.p`
    color: #FFFF;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
`