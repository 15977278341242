import React from 'react'
import { Avatar } from '@material-ui/core'
import styled from 'styled-components'
import caseTheme from '../caseTheme'

const ColorAvatar = styled(Avatar)`
    background-color: ${caseTheme.accentColor};
    color: ${caseTheme.primaryColor};
    margin-bottom: ${props => props.theme.space[2]}px;
`

export default ColorAvatar
