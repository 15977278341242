import { Grid } from '@material-ui/core'
import { 
    DevicesOutlined, 
    ScheduleOutlined, 
    ErrorOutlineOutlined,
    AssignmentIndOutlined,
    ListOutlined,
    LocalActivityOutlined } from '@material-ui/icons'
import React from 'react'
import { Paragraph, Subtitle, Title } from '../Typography'
import ColorAvatar from '../components/ColorAvatar'
import styled from 'styled-components'
import image2 from '../../../../assets/casestudies/image2.png'

const paragraph1 = "Cine Vista is a multiplex theater in Tradroe, a small town in Central Pennsylvania. They strive to provide the best movie-going experience for their patrons. Cine Vista’s movies are offered in standard, 3D, and IMAX formats. Their target customers are local residents and visitors who want to find movies and reserve seats quickly."
const paragraph2 = "People want to find movies and reserve seats quickly but the Cine Vista web app features a sub-optimal experience."
const paragraph3 = "Design an app for Cine Vista that can compete in the market, improve ticket sales, and increase customer satisfaction."
const paragraph4 = "Designing an app for Cine Vista from conception to delivery."
const paragraph5 = "Conducting interviews, paper and digital wireframing, low and high-fidelity prototyping, conducting usability studies, accounting for accessibility, and iterating on designs."

const InfoItem = ({icon, title, text}) => {
    return (
        <>
            <ColorAvatar>
                {icon}
            </ColorAvatar>
            <Subtitle>{title}</Subtitle>
            <Paragraph>{text}</Paragraph>
        </>
    )
}

const Image = styled.img`
    max-width: 50%;
    margin-bottom: ${props => props.theme.space[3]}px;
    margin-top: ${props => props.theme.space[3]}px;
`
const Overview = () => {
    return (
        <>
            <Title>Project Overview</Title>
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <InfoItem icon={<DevicesOutlined/>} title="The Product" text={paragraph1} />
                    <InfoItem icon={<ScheduleOutlined/>} title="Project Duration" text="March 2021 to May 2021." />
                </Grid>
                <Grid item container md={6} justify="center">
                    <Image src={image2} />
                </Grid>
                <Grid item md={6}>
                    <InfoItem icon={<ErrorOutlineOutlined/>} title="The Problem" text={paragraph2} />
                </Grid>
                <Grid item md={6}>
                    <InfoItem icon={<LocalActivityOutlined/>} title="The Goal" text={paragraph3} />
                </Grid>
                <Grid item md={6}>
                    <InfoItem icon={<AssignmentIndOutlined/>} title="Our role" text={paragraph4} />
                </Grid>
                <Grid item md={6}>
                    <InfoItem icon={<ListOutlined />} title="Our Responsibilities" text={paragraph5} />
                </Grid>
            </Grid>
        </>
    )
}

export default Overview
